/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages
        //
        navbarButtonToggle = {
          bindings: function() {
            var $this = $('.navbar-toggle');
            $this.on('click', function() {
              $this.toggleClass('open');
            });
            $('.login-register-mobile-link').on('click', function() {
              $this.removeClass('open');
              $('.navbar-responsive-collapse').collapse('hide');
            });

            $('.mobile-only-menu-item').prependTo('#mobile-only-list');
          }
        },
        loginPaneCollapse = {
          bindings: function() {
            // Login open
            $('[data-toggle="loginRegisterForm"], #btn-close').on('click', function() {

              var $loginRegisterTab = $('#loginRegisterTab');
              var $loginContent = $('#loginContent');
              // var $box = $loginRegisterTab;
              // var $link = $('[data-toggle="loginRegisterForm"]');

              $loginRegisterTab.toggleClass('open in');
              $loginContent.toggleClass('in');

              if ($loginRegisterTab.hasClass('open in')) {

                $('#btnCreateAccount').on('click', function() {
                  var $loginContent = $('#loginContent');
                  $loginContent.removeClass('in');
                  $loginContent.attr('aria-expanded', 'false');
                });

                $('#btnForgetPassword').on('click', function() {
                  var $loginContent = $('#loginContent');
                  $loginContent.removeClass('in');
                  $loginContent.attr('aria-expanded', 'false');
                });

                $('#btnLoginAccount').on('click', function() {
                  var $registerContent = $('#loginContent');
                  $registerContent.removeClass('in');
                  $registerContent.attr('aria-expanded', 'false');
                });

                // $(window).on("click.Bst", function(event){
                //   if (
                //     $box.has(event.target).length == 0 //checks if descendants of $box was clicked
                //     &&
                //     !$box.is(event.target) //checks if the $box itself was clicked
                //     &&
                //     !$link.is(event.target)
                //   ){
                //     console.log('you clicked outside the box');
                //     $loginRegisterTab.hide();
                //   } else {
                //     console.log('you clicked inside the box');
                //     $loginRegisterTab.show();
                //   }
                // });

              }
            });
            // $('#btn-close').on('click', function() {
            //   var $loginRegisterTab = $('#loginRegisterTab');
            //   var $loginContent = $('#loginContent');
            //   $loginRegisterTab.toggleClass('open in');
            //
            //   $loginContent.attr('aria-expanded', 'false');
            //   $registerContent.attr('aria-expanded', 'false');
            //   // $loginContent.toggleClass('in');
            // });
          }
        },
        styleAllSelects = {
          bindings: function() {
            // hide the .select-label class
            $('.select-label').each(function() {
              var $this = $(this);
              $this.addClass('select-hidden');
            });

            // replace select-inputs with custom style
            $('select:not([multiple],.f-select-styling)').each(function() {
              console.log('Restyling: ', this);
              var $this = $(this), numberOfOptions = $(this).children('option').length;
              $this.addClass('select-hidden');
              $this.wrap('<div class="select"></div>');
              $this.after('<div class="select-styled"></div>');

              var $styledSelect = $this.next('div.select-styled');
              $styledSelect.text($this.children('option').eq(0).text());

              var $list = $('<ul />', {
                'class': 'select-options'
              }).insertAfter($styledSelect);

              for (var i = 0; i < numberOfOptions; i++) {
                $('<li />', {
                  text: $this.children('option').eq(i).text(),
                  rel: $this.children('option').eq(i).val()
                }).appendTo($list);
              }

              var $listItems = $list.children('li');

              $styledSelect.click(function(e) {
                e.stopPropagation();
                $('div.select-styled.active').not(this).each(function() {
                  $(this).removeClass('active').next('ul.select-options').hide();
                });
                $(this).toggleClass('active').next('ul.select-options').toggle();
              });

              $listItems.click(function(e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('active');
                $this.val($(this).attr('rel'));
                $list.hide();
                //console.log($this.val());
              });

              $(document).click(function() {
                $styledSelect.removeClass('active');
                $list.hide();
              });
            });

            // replace multiple selects with checkboxes
            $('select[multiple]').each(function() {
              console.log('Restyling multiple: ', this);
              // var $this = $(this), numberOfOptions = $(this).children('option').length;
              $(this).addClass('f-select-styling js-multiple');
            });
          }
        },
        toggleFilters = {
          bindings: function() {

            // Sidebar Filters Toggle
            //
            $('.list-toggle').on('click', function() {
              $(this).toggleClass('active');
            });
            // Mobile Filter Toggle
            //
            $('.immo-sidebar').each(function() {
              $('[data-toggle="immo-sidebar-list"]').on('click', function() {
                $(this).toggleClass('open');
                $('#immo-sidebar-list').toggleClass('open');
              });
            });
          }
        };

        // Fix Telephone Links
        $('a[href^="tel:"]').attr('href', function(_,v){
            return v.replace(/((\(0\))|\s+)/g,'')
         });

        toggleFilters.bindings(),
        navbarButtonToggle.bindings(),
        loginPaneCollapse.bindings(),
        styleAllSelects.bindings();

        var $searchOpenBtn = $('.js-search-open-btn');
        if ($searchOpenBtn) {
          var modal = new tingle.modal({
            closeMethods: ['overlay', 'button', 'escape'],
            closeLabel: "Close",
            onClose: function () {
            }
          });

          // set content
          var $searchPopup = $('.js-search-popup');
          modal.setContent($searchPopup[0].innerHTML);

          $('.js-search-form').parsley();

          if ($('.js-multiple').fSelect) {
            window.setTimeout(function () {
              $('.js-multiple').fSelect({
                placeholder: 'Plaats',
                showSearch: false
              });
            }, 250);
          }

          $searchOpenBtn.on('click', function() {
            modal.open();
          });
        }

        // Toggle the dropdown arrow
        //
        // $('.facetwp-facet').on('click', function() {
        //   var $this = $(this);
        //   if ($('.fs-dropdown').hasClass('hidden')) {
        //     $this.toggleClass('open');
        //   }
        // });

        // Search modal
        var $searchModalToggle = $('.js-search-modal-toggle');
        if ($searchModalToggle) {
          $searchModalToggle.on('click', function() {
            $('.js-search-modal').toggleClass('closed');
          })
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // specific function for facetWP
        $(document).bind('facetwp-loaded', function() {

          if (window.location.search !== '?fwp_huur_of_koop=koop' && window.location.search !== '?fwp_huur_of_koop=huur' &&  $('.realestate-archive').length) {
            $('html, body').animate({ scrollTop: $('.realestate-archive').offset().top }, 500);
          }

          // facetwp dropdowns styling
          $('.facetwp-type-dropdown').each(function() {
            var $this = $(this);
            $this.addClass('immo-filter-flexbox__content btn-group btn-group-auto-width');
          });
          // facetwp radio-buttons styling
          $('.facetwp-number-range-option').each(function() {
            var $this = $(this);
            $this.addClass('immo-radio');
            $this.children('input').after('<i class="immo-radio__icon"></i>');
          });

          // FSelect example, initialiseer dit op een 'select' element.
          //
          // Huur of Koop FacetWP
          //
          $('.facetwp-facet-huur_of_koop').each(function() {
            var $this = $(this).find('.fs-search');
            $this.remove('.fs-search');
          });

          // Aanbod FacetWP
          //
          $('.facetwp-facet-type_aanbod').each(function() {
            var $this = $(this).find('.fs-search');
            $this.remove('.fs-search');
          });


          // Prijs vanaf FacetWP
          //
          $fromSelect = $('.facetwp-facet-dropdown_prijs_vanaf select');
          if ($fromSelect.fSelect) {
            $fromSelect.fSelect({
              showSearch: false
            });
          }

          // Prijs tot FacetWP
          //
          $tillSelect = $('.facetwp-facet-dropdown_prijs_tot select');
          if ($tillSelect.fSelect) {
            $tillSelect.fSelect({
              showSearch: false
            });
          }

          window.setTimeout(function () {
            // Get current type: 'koop' of 'huur'
            var selectedOption = $('.facetwp-facet-huur_of_koop select.facetwp-dropdown option[selected]');
            if (selectedOption.length && selectedOption.val() === 'huur') {
              $('.facetwp-facet-dropdown_prijs_vanaf .fs-dropdown .fs-option').each(function (index, element) {
                if ($.inArray($(element).attr('data-value'), ['500-', '650-', '800-', '1000-', '1500-']) > -1) {
                  $(element).show();
                } else {
                  $(element).hide();
                }
              });
              $('.facetwp-facet-dropdown_prijs_tot .fs-dropdown .fs-option').each(function (index, element) {
                if ($.inArray($(element).attr('data-value'), ['-500', '-650', '-800', '-1000', '-1500']) > -1) {
                  $(element).show();
                } else {
                  $(element).hide();
                }
              });
            } else {
              $('.facetwp-facet-dropdown_prijs_vanaf .fs-dropdown .fs-option').each(function (index, element) {
                if ($.inArray($(element).attr('data-value'), ['500-', '650-', '800-', '1000-', '1500-']) > -1) {
                  $(element).hide();
                } else {
                  $(element).show();
                }
              });
              $('.facetwp-facet-dropdown_prijs_tot .fs-dropdown .fs-option').each(function (index, element) {
                if ($.inArray($(element).attr('data-value'), ['-500', '-650', '-800', '-1000', '-1500']) > -1) {
                  $(element).hide();
                } else {
                  $(element).show();
                }
              });
            }
          }, 200);

        //   $('.facetwp-facet-type_aanbod select').fSelect({
        //    placeholder: 'Select some options',
        //    numDisplayed: 4,
        //    // overflowText: '{n} selected',
        //   //  searchText: 'Search',
        //    showSearch: false
        //  });

          // Fire styleAllSelects agian when facet is fully loaded
          // styleAllSelects.bindings();
        });
        $(document).bind('gform_post_render', function() {

          $('.validation_message').on('click', function() {
            var $this = $(this);
            var $gfield = $('.gfield');
            $this.remove();
            $('.gfield').css('margin-bottom', '0');
            // $gfield.parent().siblings().removeClass('gfield_error');
          });

          styleAllSelects.bindings();
          // textareaAddRows.bindings();
        });
      }
    },
    // Home page
    //
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var homeSwiperSLider = new Swiper('#home-swiper-slider', {
          spaceBetween: 0,
          autoplay: 4800,
          effect: 'fade'
        });
        var homeRecentAdded = new Swiper('#home-swiper-recent-added', {
          pagination: '.swiper-pagination',
          slidesPerView: 4,
          slidesPerColumn: 1,
          slidesPerGroup: 4,
          paginationClickable: true,
          spaceBetween: 24,

          breakpoints: {
            1280: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            991: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            767: {
              slidesPerView: 1,
              slidesPerColumn: 2,
              slidesPerGroup: 1,
              allowSwipeToPrev: false,
              allowSwipeToNext: false
            }
          }
        });
      }
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    },
    // About us page, note the change from about-us to about_us.
    //
    'cb_filter': {
      init: function() {

        // FacetWP Checkboxen (zie: https://facetwp.com/how-to-customize-the-facet-loader/)
        $(function() {
            // Make checkbox options semi-transparent
            FWP.loading_handler = function(params) {
                params.element.find('.facetwp-checkbox').css('opacity', 0.5);
            }
        });

      }
    },
    // Te koop page
    //
    'te_koop': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // var $desktopFilters = $('.immo-sidebar--desktop');
        // var $mobileFilters = $('.immo-sidebar--mobile');
        //
        // $(window).resize(function(){
        //     if ($(window).width() >= 768){
        //
        //     $desktopFilters.remove();
        //
        //     }    else if ($(window).width() <= 767) {
        //
        //      $mobileFilters.remove();
        //
        //   }
        // });

        // $(window).resize(function () {
        //   var viewportWidth = $(window).width();
        //   if (viewportWidth <= 767) {
        //     $('.immo-sidebar--desktop').remove();
        //   } else if (viewportWidth >= 768) {
        //     $('.immo-sidebar--mobile').remove();
        //   }
        // });

      }
    },
    'te_koop_single': {
      init: function() {
        // JavaScript to be fired on the te-koop-single
        //

        // Init Large Gallery
        //
        initLargeGallery = {
          bindings: function () {
            var galleryLarge = new Swiper('.realestate-gallery__large-item', {
              autoplay: 4800,
              effect: 'fade'
            });
          }
        },

        // Init Thumbs Gallery
        //
        initThumbsGallery = {
          bindings: function() {

            var galleryThumbs = new Swiper('.realestate-gallery__thumbs', {
              nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',
              spaceBetween: 20,
              centeredSlides: false,
              preventClicks: true,
              // slidesOffsetBefore: -140,

              slidesPerView: 2,
              slidesPerColumn: 2,
              slidesPerGroup: 1,

              breakpoints: {
                991: {
                  slidesOffsetBefore: 0,
                  slidesPerView: 1,
                  slidesPerColumn: 1,
                  slidesPerGroup: 1
                }
              },
              touchRatio: 0.2,
              slideToClickedSlide: false
            });
          }
        },
        // Features List Items
        //
        featuresListItems = {
          bindings: function() {

            $('.realestate-feature__list').each(function() {
              var $this = $(this);

              if ( $this.children('li').length > 8 && $this.hasClass('allow-2-columns') ) {
                $this.addClass('ul-two-columns');
              } else {
                $this.addClass('ul-one-column');
              }
            });
          }
        },
        scrollToAppointment = {
          bindings: function() {
            $('#scrollToAppointment').on('click', function(event) {
              $('html, body').animate({
                scrollTop: $('.appointment-maker-form').offset().top
              }, 1000);
            });
          }
        },
        styleAppointmentForm = {
          bindings: function() {

            $('#gform_2').each(function() {
              var $this = $(this);
              $this.find('ul#gform_fields_2').addClass('appointment-form-flexbox');
              $this.find('ul#gform_fields_2 li').addClass('appointment-form-flexbox__content');
              $this.find('.gform_footer').addClass('appointmentSubmit appointment-form-flexbox__content').appendTo('.gform_body > ul');
            });
            $('.appointmentCall').appendTo('.gform_body > ul');
          }
        },
        textareaAddRows = {
          bindings: function() {
            if($(window).width() >= 992) {
              $('#appointmentQuestionsTextarea').attr('rows', '1');
              $('#input_2_6').attr('rows', '1');
            } else if ($(window).width() <= 991) {
              $('#appointmentQuestionsTextarea').attr('rows', '4');
              $('#input_2_6').attr('rows', '4');
            }
          }
        };
        initLargeGallery.bindings(),
        initThumbsGallery.bindings(),
        featuresListItems.bindings(),
        scrollToAppointment.bindings(),
        styleAppointmentForm.bindings(),
        textareaAddRows.bindings();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $(window).on('resize', function() {
          textareaAddRows.bindings();
        });
        $(document).bind('gform_post_render', function() {
          styleAppointmentForm.bindings();
        });
      }
    },
    'backend': {
      init: function() {
        // JavaScript to be fired on the te-koop-single
        //
        // Init Thumbs Gallery
        //
        initThumbsGallery = {
          bindings: function() {

            var galleryThumbs = new Swiper('.realestate-gallery__thumbs', {
              nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',
              spaceBetween: 20,
              centeredSlides: true,
              preventClicks: true,
              slidesOffsetBefore: -140,

              slidesPerView: 2,
              slidesPerColumn: 2,
              slidesPerGroup: 1,

              breakpoints: {
                991: {
                  slidesOffsetBefore: 0,
                  slidesPerView: 1,
                  slidesPerColumn: 1,
                  slidesPerGroup: 1
                }
              },
              touchRatio: 0.2,
              slideToClickedSlide: false
            });

            var $targetGalleryImage = $('#targetGalleryImage');
            $('.swiper-slide--image').on('click', function(event) {
              $targetGalleryImage.css('background-image', $(event.target).css('background-image'));
            });
          }
        }
        initThumbsGallery.bindings();
      }
    },
    // Testpage
    //
    'nieuwbouw_renovatie': {
      init: function() {
        // JavaScript to be fired on the testpage
        var nieuwbouwSwiper = new Swiper('.nieuwbouw-renovatie__items', {
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          pagination: '.swiper-pagination',
          slidesPerView: 4,
          slidesPerColumn: 1,
          slidesPerGroup: 4,
          paginationClickable: true,
          spaceBetween: 4,

          breakpoints: {
            1280: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            991: {
              slidesPerView: 2,
              slidesPerGroup: 2
            },
            767: {
              slidesPerView: 1,
              slidesPerColumn: 4,
              slidesPerGroup: 1,
              allowSwipeToPrev: false,
              allowSwipeToNext: false
            }
          }
        });
      }
    },
    'nieuws': {
      init: function() {

        singleGallery = {
          bindings: function() {
            var gridContainer = $('#grid-container'),
              filtersContainer = $('#filters-container'),
              wrap, filtersCallback;

            gridContainer.cubeportfolio({
              defaultFilter: '*',
              animationType: 'slideLeft',
              gapHorizontal: 15,
              gapVertical: 15,
              gridAdjustment: 'responsive',
              mediaQueries: [
                {
                  width: 768,
                  cols: 4,
                },
                {
                  width: 320,
                  cols: 2
                }
              ],
              caption: 'zoom',
              displayType: 'lazyLoading',
              displayTypeSpeed: 100
            });

            if (filtersContainer.hasClass('cbp-l-filters-dropdown')) {
              wrap = filtersContainer.find('.cbp-l-filters-dropdownWrap');

              wrap.on({
                'mouseover.cbp': function() {
                  wrap.addClass('cbp-l-filters-dropdownWrap-open');
                },
                'mouseleave.cbp': function() {
                  wrap.removeClass('cbp-l-filters-dropdownWrap-open');
                }
              });

              filtersCallback = function(me) {
                wrap.find('.cbp-filter-item').removeClass('cbp-filter-item-active');
                wrap.find('.cbp-l-filters-dropdownHeader').text(me.text());
                me.addClass('cbp-filter-item-active');
                wrap.trigger('mouseleave.cbp');
              };
            } else {
              filtersCallback = function(me) {
                me.addClass('cbp-filter-item-active').siblings().removeClass('cbp-filter-item-active');
              };
            }
            filtersContainer.on('click.cbp', '.cbp-filter-item', function() {
              var me = $(this);

              if (me.hasClass('cbp-filter-item-active')) {
                return;
              }
              // get cubeportfolio data and check if is still animating (reposition) the items.
              if (!$.data(gridContainer[0], 'cubeportfolio').isAnimating) {
                filtersCallback.call(null, me);
              }
              // filter the items
              gridContainer.cubeportfolio('filter', me.data('filter'), function() {});
            });

            gridContainer.cubeportfolio('showCounter', filtersContainer.find('.cbp-filter-item'), function() {
              // read from url and change filter active
              var match = /#cbpf=(.*?)([#|?&]|$)/gi.exec(location.href),
                item;
              if (match !== null) {
                item = filtersContainer.find('.cbp-filter-item').filter('[data-filter="' + match[1] + '"]');
                if (item.length) {
                  filtersCallback.call(null, item);
                }
              }
            });
          }
        };
        singleGallery.bindings();
      }
    },
    'maps': {
      init: function() {

        //
        // GOOGLE MAPS
        //
          /*
          *  new_map
          *
          *  This function will render a Google Map onto the selected jQuery element
          *
          *  @type  function
          *  @date  8/11/2013
          *  @since 4.3.0
          *
          *  @param $el (jQuery element)
          *  @return  n/a
          */

          function new_map( $el ) {

            // var
            var $markers = $el.find('.marker');


            // vars
            var args = {
              zoom    : 16,
               scrollwheel: false,
              center    : new google.maps.LatLng(0, 0),
              mapTypeId : google.maps.MapTypeId.ROADMAP
            };


            // create map
            var map = new google.maps.Map( $el[0], args);


            // add a markers reference
            map.markers = [];


            // add markers
            $markers.each(function(){

                add_marker( $(this), map );

            });


            // center map
            center_map( map );


            // return
            return map;

          }

          /*
          *  add_marker
          *
          *  This function will add a marker to the selected Google Map
          *
          *  @type  function
          *  @date  8/11/2013
          *  @since 4.3.0
          *
          *  @param $marker (jQuery element)
          *  @param map (Google Map object)
          *  @return  n/a
          */

          function add_marker( $marker, map ) {

            // var
            var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

            // create marker
              // Added retina marker
              if($("body").hasClass("contact")){
                // Contact Marker
                var cbMarker = new google.maps.MarkerImage('/app/themes/immo/dist/images/pinpoint@2x.png', null, null, null, new google.maps.Size(42,61));
              } else {
                // Normal Marker
                var cbMarker = new google.maps.MarkerImage('/app/themes/immo/dist/images/pinpoint-normal@2x.png', null, null, null, new google.maps.Size(34,50));
              };

            var marker = new google.maps.Marker({
              position  : latlng,
              map     : map,
              icon: cbMarker
            });

            // add to array
            map.markers.push( marker );

            // if marker contains HTML, add it to an infoWindow
            if( $marker.html() )
            {
              // create info window
              var infowindow = new google.maps.InfoWindow({
                content   : $marker.html()
              });

              // show info window when marker is clicked
              google.maps.event.addListener(marker, 'click', function() {

                infowindow.open( map, marker );

              });
            }

          }

          /*
          *  center_map
          *
          *  This function will center the map, showing all markers attached to this map
          *
          *  @type  function
          *  @date  8/11/2013
          *  @since 4.3.0
          *
          *  @param map (Google Map object)
          *  @return  n/a
          */

          function center_map( map ) {

            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each( map.markers, function( i, marker ){

              var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

              bounds.extend( latlng );

            });

            // only 1 marker?
            if( map.markers.length == 1 )
            {
              // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( 16 );
            }
            else
            {
              // fit to bounds
              map.fitBounds( bounds );
            }

          }

          /*
          *  document ready
          *
          *  This function will render each map when the document is ready (page has loaded)
          *
          *  @type  function
          *  @date  8/11/2013
          *  @since 5.0.0
          *
          *  @param n/a
          *  @return  n/a
          */
          // global var
          var map = null;

          $('.cb_map').each(function(){

            // create map
            map = new_map( $(this) );

          });
        //
        // END GOOGLE MAPS
        //


      }
    },
    'contact': {
      init: function() {
      // JavaScript to be fired on the contacpage

        initContactSwiper = {
          bindings: function() {
            var galleryTop = new Swiper('.contact-gallery__large-item', {
              nextButton: '.swiper-button-next',
              prevButton: '.swiper-button-prev',
              spaceBetween: 0
            });

            $('.contact-gallery__thumbs .contact-gallery__thumb').click(function () {
              var slideIndex = $(this).data('slide-index');
              galleryTop.slideTo(slideIndex);
            });
          }
        },
        styleContactForm = {
          bindings: function() {

            // $('#gform_1').each(function() {
            //
            //   $(document).bind('gform_post_render', function() {
            //
            //
            //   });
            // });

          }
        };
        initContactSwiper.bindings();
        // styleContactForm.bindings();
      },
      finalize: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
